// -----------------------------------------------------------------------------
// This file contains variables
// -----------------------------------------------------------------------------

$text-font: "Roboto", sans-serif;
$title-font: "Montserrat", sans-serif;

$font-size: rem(16);
$font-weight: 400;
$line-height: 1.7;

$base-color: #ebf6fb;
$base-color--light: #ffffff;
$base-color--dark: #2d343d;

$text-color: #02334a;
$text-color--gray: #656565;
$text-color--light: #fff;

$primary-color: #02334a;
$primary-color--dark: darken($primary-color, 10%);
$secondary-color: #f8651f;
$tertiary-color: #068dcd;

$gradient-1-primary: #635ee2;
$gradient-1-secondary: #0085ff;

$border-color: #e5e7eb;

$box-shadow: 0px 36px 198px rgba(0, 0, 0, 0.07),
    0px 15.0399px 82.7197px rgba(0, 0, 0, 0.0503198),
    0px 8.04107px 44.2259px rgba(0, 0, 0, 0.0417275),
    0px 4.50776px 24.7927px rgba(0, 0, 0, 0.035),
    0px 2.39404px 13.1672px rgba(0, 0, 0, 0.0282725),
    0px 0.996212px 5.47917px rgba(0, 0, 0, 0.0196802);

$radius: 4px;

$grid-gutter: 1rem;
$main-gutter: 4.813rem;
$header-gutter: 6.5rem;

$letter-spacing: (
    "sm": 0.02em,
    "lg": 0.15em,
);

$breakpoints: (
    "sm": 576px,
    "md": 768px,
    "lg": 992px,
    "xl": 1200px,
);

$global-font-sizes: (
    "xs": 80%,
    "sm": 85%,
    "md": 90%,
    "lg": 95%,
    "xl": 100%,
);

$gutters: (
    "xs": 0.5rem,
    "sm": 1rem,
    "md": 3rem,
    "lg": 5rem,
    "xl": 8rem,
);
